<template>
  <div>
    <Heading heading="h4" content="Tests" class="mb-4"></Heading>
    <hr />
    <div v-if="tests">
      <div v-if="tests.length > 0" style="max-width: 1080px;">
        <el-table :data="tests" style="width: 100%;">
          <el-table-column label="Test" width="50px">
            <template slot-scope="scope">
              <b class="test-color">
                {{ scope.row.teaching_resource.content.test.name }}
              </b>
            </template>
          </el-table-column>
          <el-table-column label="Material Name" width="150">
            <template slot-scope="scope">
              <b>
                {{ scope.row.teaching_resource.name }}
              </b>
            </template>
          </el-table-column>
          <el-table-column label="Test Name">
            <template slot-scope="scope">
              <b>
                {{ scope.row.teaching_resource.content.name }}
              </b>
            </template>
          </el-table-column>
          <el-table-column prop="Score" label="Score" width="300">
            <template slot-scope="scope">
              <div v-if="scope.row.user_progresses[0].user_exam">
                <TestScore
                  :examId="scope.row.teaching_resource.content.id"
                  :userExam="scope.row.user_progresses[0].user_exam"
                  :testType="scope.row.teaching_resource.content.test.name"
                  role="Student"
                ></TestScore>
              </div>
              <div v-else>
                <div v-if="scope.row.user_progresses[0].status == 'locked'">
                  <router-link
                    :to="{
                      name: 'StudentPlans'
                    }"
                  >
                    <el-tooltip
                      effect="dark"
                      :content="
                        `Unlock all ${scope.row.teaching_resource.content.test.name} tests`
                      "
                      placement="top"
                    >
                      <el-button type="info" style="width: 100%">
                        <i class="fa fa-lock"></i>
                      </el-button>
                    </el-tooltip>
                  </router-link>
                </div>
                <el-button
                  v-else
                  type="primary"
                  style="width: 100%"
                  @click="
                    startTest(
                      scope.row.teaching_resource.id,
                      scope.row.teaching_resource.content.id,
                      scope.row.teaching_resource.content.test.name
                    )
                  "
                >
                  <i class="fas fa-play"></i>
                  Start a Test
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Date" label="Date" width="100">
            <template slot-scope="scope">
              <template
                v-if="
                  scope.row.user_progresses &&
                    scope.row.user_progresses[0].user_exam
                "
              >
                <span>
                  {{
                    instant.formatGoogleTime(
                      scope.row.user_progresses[0].user_exam.updated_at
                    )
                  }}
                </span>
              </template>
              <template v-else>
                -
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-empty v-else description="No Tests"></el-empty>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Tests from "@/views/courses/tests/apis/tests.js";
import Common from "@/mixins/common.js";
import Materials from "@/views/courses/materials/apis/materials";
import TestScore from "@/views/courses/tests/components/TestScore";

export default {
  metaInfo() {},

  components: { TestScore },

  mixins: [Common],

  props: [],
  data() {
    return {
      tests: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    ...mapState("user", ["token", "lang"]),
    myTitle() {
      return "Tests";
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.id) {
      const res = await Tests.getTests(this.$route.params.id);
      this.tests = res;
    }
  },

  methods: {
    async startTest(fileId, examId, testName) {
      const res = await Materials.getExam(examId);
      let userExamId = res.user_exam.id;
      await Materials.bindExamToClasses(fileId, {
        user_exam_id: userExamId
      });
      this.$router.push({
        name: testName + "Test",
        params: {
          examId: examId,
          userExamId: userExamId
        }
      });
    }
  }
};
</script>

<style scoped></style>
